import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import {
  Text,
  Badge,
  Box,
  Page,
  PageSection,
  Card,
  Cell,
  Layout,
  MarketingLayout,
  Button,
  Image,
  WixDesignSystemProvider,
  Modal,
  AnnouncementModalLayout,
  FormField,
  CheckToggle,
  Input
} from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import { PremiumFilled } from '@wix/wix-ui-icons-common';
import { ReactTagManager } from 'react-gtm-ts';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
};

const appName = {
  '99380718-9366-474d-a7ca-e62ac683cd5f': '綠界科技 ECPay 支付整合',
  '4f759c16-8198-4dd6-85d7-ecd0a086af9b': '綠界科技 ECPay 物流整合',
};

function App() {
  const { t } = useTranslation();

  ReactTagManager.init(tagManagerArgs);
  const appId = new URLSearchParams(window.location.search).get('appId');
  const siteUrl = new URLSearchParams(window.location.search).get('siteUrl');

  const VeloCard = (
    <Cell span={12}>
      <Card>
        <MarketingLayout
          size="medium"
          title={t('velo.title')}
          description={t('velo.description')}
          actions={<Button as="a" href="https://calendly.com/certifiedcode/meet" target="_blank">{t('velo.button')}</Button>}
          image={
            <Image height={"200px"} fit="contain" transparent={true} src='https://static.wixstatic.com/media/5305c5_385630a87104449b8f3c457bb3b54ad6~mv2_d_3840_1600_s_2.jpg'></Image>
          }
        />
      </Card>
    </Cell>
  );

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Header
          title={(appName as any)[(appId as any)] || t('title')}
          subtitle={t('subtitle')}
          actionsBar={<Button as="a" href={t('ctaLink')} target="_blank" skin="premium" prefixIcon={<PremiumFilled />}>{t('actionsBar')}</Button>}
        />
        <Page.Content>
          <Layout>
            {VeloCard}
          </Layout>
          <Layout>
            <Cell>
              <PageSection title={t('recommended.title')} showDivider />
            </Cell>
            <Cell span={6}>
              <Card>
                <MarketingLayout
                  title={t('wixDiscountCard.title')}
                  description={t('wixDiscountCard.description')}
                  actions={<Button as="a" href="https://www.certifiedcode.us/pricing/sites" target="_blank" size="small">{t('wixDiscountCard.button')}</Button>}
                  size="tiny"
                  hiddenBadge
                  alignItems="stretch"
                />
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;